import request from "@/api/config.js";

export function addUserGoal(data) {
  return request({
    url: `reg/goal/add-user-goal`,
    method: "post",
    data,
  });
}

export function addUserSource(data) {
  return request({
    url: `reg/source/add-user-source`,
    method: "post",
    data,
  });
}

export function sendInfoDiscord(data) {
  return request({
    url: `reg/send-info-discord`,
    method: "post",
    data,
  });
}

export function getUserRegistrationFields(id) {
  return request({
    url: `reg/${id}`,
    method: "get",
  });
}
