import request from "@/api/config.js";

export function updateStageStep(id, data) {
  return request({
    url: `stage-step/${id}`,
    method: "put",
    data,
  });
}

export function createStageStep(data) {
  return request({
    url: `stage-step/create`,
    method: "post",
    data,
  });
}

export function deleteStageStep(id) {
  return request({
    url: `stage-step/${id}`,
    method: "delete",
  });
}

export function getAllSteps() {
  return request({
    url: `stage-step/getAllSteps`,
    method: "post",
  });
}
export function getUsersList() {
  return request({
    url: `stage-step/getUsersList`,
    method: "post",
  });
}

export function addStepsToUser(id, data) {
  return request({
    url: `stage-step/${id}/add-steps-to-user`,
    method: "post",
    data,
  });
}
export function removeStepsFromUser(id, data) {
  return request({
    url: `stage-step/${id}/remove-steps-from-user`,
    method: "post",
    data,
  });
}
export function findAllUserSteps(id) {
  return request({
    url: `stage-step/${id}`,
    method: "get",
  });
}
