<template>
  <div class="News">
    <div
      class="News-Block"
      v-for="(post, pindx) in News.List"
      :key="`Post-[${pindx}]-${post.id}`"
      @click="callbackClick(post.link_name)"
    >
      <div class="NB-HeadnDesc ql-snow">
        <span class="NB-Header">{{ post.title }}</span>
        <span class="NB-Desc" v-html="post.desc"></span>
      </div>

      <div class="NB-Info">
        <span class="NB-Date">{{ getFormatedDate(post.createdAt) }}</span>
        <router-link :to="`/beta/news-details?post=${post.link_name}`">
          <span class="BlueButton noselect">Перейти к новости</span>
        </router-link>
      </div>
    </div>

    <span class="NewsIsEmpty" v-if="News.List.length == 0">
      Список новостей сейчас пуст
    </span>
  </div>
</template>

<script>
import { getNewsByFilters } from "@/api/news.js";

export default {
  props: {
    useReadyList: {
      type: Boolean,
      default: false,
    },
    readyList: {
      type: Array,
      default: [],
    },
    callbackClick: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      News: {
        List: [],
      },
    };
  },
  async mounted() {
    if (!this.useReadyList) {
      let _news_req = await getNewsByFilters();
      this.News.List = _news_req.data;
    } else {
      this.News.List = this.readyList;
    }
  },
  methods: {
    getMonthName(month) {
      const monthNames = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ];
      return monthNames[month];
    },
    getFormatedDate(createdAt) {
      const originalDate = new Date(createdAt);

      // Преобразование даты в нужный формат
      const day = originalDate.getDate();
      const month = this.getMonthName(originalDate.getMonth());
      const year = originalDate.getFullYear();

      return `${day} ${month} ${year}`;
    },
  },
};
</script>

<style scoped>
.NewsIsEmpty {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 15px;
  color: white;
  text-align: center;
}

.News {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: 100%;
  height: fit-content;
}
.News-Block {
  position: relative;
  display: flex;

  padding: 20px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  width: 100%;
  height: auto;
  min-height: 150px;

  border-radius: var(--o-s-global-border-radius);
  background-color: #27235c;
}

.NB-HeadnDesc {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
}
.NB-Header {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #ffffffe5;
}
.NB-Desc {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #ffffffe5;
}

.NB-Info {
  position: relative;
  display: flex;

  margin-top: auto;

  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: fit-content;
}
.NB-Info > * {
  margin: auto 0%;
}
.NB-Date {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;

  color: #d3caffb2;
}

.BlueButton {
  position: relative;
  display: block;

  cursor: pointer;

  padding: 8px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #ffffffe5;

  transition: 0.4s;

  border-radius: 4px;
  background-color: #656bff40;
}
.BlueButton:hover {
  opacity: 0.8;
}
</style>
