import request from "@/api/config.js";

export function getCoinsValueForUser(id) {
  return request({
    url: `user_coins/get/${id}`,
    method: "get",
  });
}

export function getCoinsHistoryForUser(id) {
  return request({
    url: `user_coins/get/history/${id}`,
    method: "get",
  });
}
