import request from "@/api/config.js";

export function findAllAchievements() {
  return request({
    url: `achievements/get/all`,
    method: "get",
  });
}

export function findByUserAchievements(id) {
  return request({
    url: `achievements/get/by-user/${id}`,
    method: "get",
  });
}

export function setAchievementToUser(data) {
  return request({
    url: `achievements/set/achievement`,
    method: "post",
    data,
  });
}

export function seenAchievement(user_id, ach_id) {
  return request({
    url: `achievements/seen/${user_id}/${ach_id}`,
    method: "post",
  });
}
