<template>
  <div class="main-container">
    <h2 class="component-title">Рекомендуемые задачи</h2>
    <div class="job-cards">
      <div
        class="TaskCard noselect"
        v-for="(obj, indx) in getFilterdJobs"
        :key="`${obj.id}${indx}`"
      >
        <div class="TaskDifficult">
          <div class="Directions">
            <span>{{ section?.name }}</span>
          </div>
          <div class="Difficulties">
            <div
              class="DifficultFilled"
              v-for="x in getTaskDifficult(obj.level)"
              :key="x"
            ></div>
            <div
              class="DifficultEmpty"
              v-for="x in 3 - getTaskDifficult(obj.level)"
              :key="x"
            ></div>
          </div>
        </div>
        <div class="TaskTitle">
          {{ obj.name }}
        </div>
        <div class="TaskFollowStatus">
          <div class="TCFLeft">
            <div class="IconPeoples"></div>
            <span
              >{{ obj.JobFollowers.length }}
              {{ correctTaskFollowersName(obj.JobFollowers.length) }}</span
            >
          </div>
          <div class="IconHeartFilled"></div>
        </div>
        <div class="DividerLine"></div>
        <div class="job-card-footer">
          <span class="ProjectText"
            >Проект:
            {{
              obj?.project == null ? obj?.n_project?.name : obj?.project?.name
            }}
          </span>
          <ButtonGradientBorder
            :style="
              !isFreeJob(obj)
                ? 'opacity: 0.6; filter: saturate(0); cursor: default;'
                : ''
            "
            ButtonText="Выбрать задачу"
            @click.native="
              () => {
                !isFreeJob(obj) ? null : PinTask(obj.id);
              }
            "
          />
        </div>
      </div>

      <!-- <template v-if="getFilterdJobs != null && getFilterdJobs?.length === 0">
        <span
          >К сожалению на данный момент подходящих задач с похожими
          компетенциями нет, вы можете выбрать другие задачи</span
        >

        <ButtonGradient ButtonText="123" />
      </template> -->
    </div>
  </div>
</template>

<script>
import { getAllBySection, getAllDifficulties, pinTask } from "@/api/job.js";
import { findById } from "@/api/user.js";
import { mapGetters, mapMutations } from "vuex";

import { createJobCandidateReq } from "@/api/job_candidate_request.js";

import ButtonGradientBorder from "@/components/CustomElements/ButtonGradientBorder";
import { numberFormat } from "highcharts";
import ButtonGradient from "@/components/Landing/ButtonGradient";

export default {
  name: "OctiJobRecs",
  components: { ButtonGradientBorder, ButtonGradient },
  props: {
    section: {
      type: Object,
      default: function() {
        return { id: 1, name: "" };
      },
    },
    pinnedTaskID: {
      type: Number,
      default: null,
    },
    updatePage: {
      type: Function,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      jobs: [],
      difficulties: [],
    };
  },
  async created() {
    await getAllBySection(this.section.id).then((res) => {
      // this.jobs = res.data.filter(
      //   (job) => job.воркерИД === null && job.activity_status === "Свободна"
      // );
      this.jobs = res.data.filter(
        (x) => x.projectId != null || x.nProjectId != null
      );
    });
    await getAllDifficulties().then((res) => (this.difficulties = res.data));
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    getFilterdJobs() {
      if (this.jobs == null || this.jobs?.length == 0) {
        return [];
      }

      return this.jobs.filter((x) => {
        let workersList =
          x?.JobWorkersList?.length == 0
            ? []
            : x?.JobWorkersList?.filter(
                (i) => i?.jobs_worker?.status === "В работе"
              );

        return (
          x?.repeatable ||
          (!x?.repeatable &&
            workersList?.length == 0 &&
            x?.activity_status != "Выполнена")
        );
      });
    },
  },
  methods: {
    ...mapMutations("auth", ["SET_USER"]),
    correctTaskFollowersName(tasksCounter) {
      if (tasksCounter % 10 == 1 && tasksCounter != 11) {
        return "следит";
      }
      return "следят";
    },
    getTaskDifficult(DiffName) {
      let indx = this.difficulties.findIndex((obj) => {
        return obj.name === DiffName;
      });
      return indx + 1;
    },
    async PinTask(taskId) {
      await createJobCandidateReq({
        action: "cancel",
        jobID: this.pinnedTaskID,
        userID: this.user.id,
      });

      await pinTask(taskId, { user_id: this.user.id }).then((res) => {
        this.updatePage();
      });
    },
    isFreeJob(job_Data) {
      // return (
      //   this?.PinnedTask?.UserPinTask[0]?.JobWorkersList.length == 0 ||
      //   this?.PinnedTask?.UserPinTask[0]?.repeatable == true
      // );
      return true;
    },
  },
};
</script>

<style scoped>
.job-card-footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;

  justify-content: space-between;
}
.component-title {
  color: var(--students-white, #e8e7ec);

  /* h2 */
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-bottom: 1.25rem;
}

.job-cards {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 4fr));

  grid-auto-flow: dense;
  grid-row-gap: 1.25rem;
  grid-column-gap: 1.5rem;

  margin-bottom: 5rem;
  padding-right: 1rem;

  overflow: scroll !important;
}

.TCFLeft > * {
  margin-top: auto;
  margin-bottom: auto;
}
.TCFLeft {
  position: relative;

  width: fit-content;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
}
.TCFLeft > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;

  /* tag */
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Difficulties {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  width: fit-content;
  height: fit-content;
}
.DifficultEmpty {
  position: relative;
  display: block;

  width: 16px;
  height: 16px;

  background: url("./../../assets/img/profile/ProfileProjectCard/DifficultIconPlaceholder.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.DifficultFilled {
  position: relative;
  display: block;

  width: 16px;
  height: 16px;

  background: url("./../../assets/img/profile/ProfileProjectCard/DifficultIcon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.IconPeoples {
  position: relative;
  display: block;

  width: 16px;
  height: 12px;

  background: url("./../../assets/img/Landing/Peoples16x12.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.TaskCard > .TaskDifficult > * {
  margin-top: auto;
  margin-bottom: auto;
}
.TaskCard > .TaskDifficult {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.TaskCard > .TaskDifficult > .Directions {
  gap: 5px;
}
.TaskCard > .TaskDifficult > .Directions > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #1d1a42;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  padding: 4px 8px;

  border-radius: 4px;
  background: #1dd185;
}

.TaskCard > .ProjectViewTasks {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  cursor: pointer;

  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%;
  text-decoration-line: underline;

  background: linear-gradient(221deg, #c548ab -3.4%, #635bff 101.08%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ProjectText {
  color: #e7f6ff;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%;
}
.TaskCard > .TaskTitle {
  color: #fff;

  /* text medium */
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 23.25px */
}

.TaskCard > .DividerLine {
  position: relative;
  display: block;

  margin: 10px 0%;

  width: 100%;
  height: 1px;

  background: #292558;
}

.TaskCard > .TaskFollowStatus {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  margin-top: 10px;

  width: 100%;
  height: fit-content;
}

.TaskCard {
  position: relative;

  margin-bottom: 15px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 20px 30px;

  border-radius: 20px;
  background: #1f1e48;
}

.TasksTip {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  margin-bottom: 15px;

  width: 100%;
  height: fit-content;

  padding: 20px 30px;

  border-radius: 20px;
  background: #292558;
}
.TasksTip > .Head {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fafafa;

  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 23.25px */
}
.TasksTip > .Text {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fafafa;

  color: #c8c3de;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
