import request from "@/api/config.js";

export function removeSetting(data) {
  return request({
    url: `setting/remove`,
    method: "post",
    data,
  });
}

export function createSetting(data) {
  return request({
    url: `setting/create`,
    method: "post",
    data,
  });
}

export function getUserSettings(id) {
  return request({
    url: `setting/${id}`,
    method: "get",
  });
}
