<template>
  <div id="ProfileProgressCard">
    <div class="Top">
      <div class="ProfileHead">
        <p class="CardTitle">Профиль</p>

        <!-- <router-link to="/profile/edit">
          <div class="SettingsButton"></div>
        </router-link> -->

        <Octocoins style="margin: auto 0%;">
          <template v-slot:content>
            <span class="OctocoinsText"
              >{{ getFormattedCoinsValue }} окто<span
                style="font-weight: 300;"
                >{{ getCoinsText }}</span
              ></span
            >
          </template>
        </Octocoins>
      </div>
      <div class="ProfileText">
        <div class="CircleWithLetter">
          <span>{{ getFirstLetter }}</span>
        </div>
        <div class="ProfileTexts">
          <span>{{ getUserName }}</span>
          <!-- <span>Цель: {{ UserGoal }}</span> -->
          <!-- <div class="XPBlock">
            <div class="XPText">
              <span class="XTCurrentText">1 уровень</span>
              <span class="XTNextText">2 уровень</span>
            </div>

            <div class="XPLine">
              <div class="XPProgressLine"></div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="Bottom">
      <p class="CardTitle">Мои достижения</p>
      <div class="MyAchList">
        <el-tooltip
          class="box-item"
          effect="customized"
          :content="`Получено достижение &laquo;${ach.name}&raquo;`"
          placement="top-start"
          v-for="(ach, aindx) in getFirstFiveAchievements"
          :key="`${aindx}${ach.id}`"
        >
          <div class="AchBlock" :style="getAchIMG(ach.url_img)"></div>
        </el-tooltip>

        <el-tooltip
          class="box-item"
          effect="customized"
          :content="`Пусто`"
          placement="top-start"
          v-for="n in 5 - getFirstFiveAchievements.length"
          :key="`${n}`"
        >
          <div class="AchBlock"></div>
        </el-tooltip>

        <router-link to="/beta/profile/achievements">
          <div class="ButtonAch">
            <span class="centerText">
              <span class="ArrowRight">&rarr;</span>
            </span>
          </div>
        </router-link>
      </div>
    </div>

    <el-dialog
      v-model="SeenAchievements"
      title="Поздравляем!"
      custom-class="DEFAULT_BRS_DIALOG"
      :before-close="checkNewAchievement"
    >
      <span class="DEFAULT_ORG_VIEW_TEXT"
        >Вы получили новое достижение &laquo;{{
          NewAchievementTitle
        }}&raquo;</span
      >
      <template #footer>
        <span
          class="DEFAULT_BRS_CLOSE_BUTTON noselect"
          @click="checkNewAchievement"
          >Закрыть</span
        >
      </template>
    </el-dialog>
    <!-- <div class="ProfileProgressCardBG"></div> -->
  </div>
</template>

<style scoped>
.OctocoinsText {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-weight: 600;
  font-size: 13px;
  line-height: normal;

  color: white;
}
.XPBlock {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  margin-top: 8px;

  width: 100%;
  height: fit-content;
}
.XPText {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: fit-content;
}
.XPText > * {
  margin-top: auto;
  margin-bottom: auto;
}
.XPLine {
  position: relative;
  display: block;

  width: 100%;
  height: 10px;

  background-color: rgb(41, 37, 88);
  border-radius: 12px;
}
.XPProgressLine {
  position: absolute;
  display: block;

  top: 0;
  left: 0;

  width: 10%;
  height: 10px;

  background-color: rgb(111, 207, 151);
  border-radius: 12px;
}
.XTCurrentText {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: rgb(232, 231, 236);
  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 400;
  line-height: 122%;
  letter-spacing: 0%;
  text-align: left;
}
.XTNextText {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: rgb(78, 70, 168);
  font-family: "Montserrat";
  font-size: 10px;
  font-weight: 400;
  line-height: 122%;
  letter-spacing: 0%;
  text-align: right;
}

.ArrowRight {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-size: 20px;
  line-height: normal;
  font-weight: 600;
  position: relative;
  display: block;
  width: fit-content;
  height: fit-content;

  color: white;
}
.ButtonAch {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;

  width: 40px;
  height: 40px;

  border-radius: 4px;
  border: 0px solid;
  backdrop-filter: blur(1px);
}

.ButtonAch::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 10px;
  padding: 1px;
  background: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.ButtonAch > .centerText {
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 3.5px;
}
.ButtonAch > .centerText > * {
  margin-top: auto;
  margin-bottom: auto;
}

.MyAchList {
  position: relative;
  display: grid;

  grid-template-columns: repeat(6, 40px);
  gap: 10px;

  width: fit-content;
  height: fit-content;
}

.MyAchList > .AchBlock {
  position: relative;
  display: block;

  width: 100%;
  height: auto;

  aspect-ratio: 1 / 1;

  border-radius: 5px;
  background: rgb(46, 43, 107);
}

.Top {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  width: 100%;
  height: fit-content;
}

.Bottom {
  position: relative;
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.CircleWithLetter > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  margin: auto;

  color: #e8e7ec;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.CircleWithLetter {
  position: relative;
  display: flex;

  width: 66px;
  height: 66px;

  border: 1px solid #ab64f5;

  border-radius: 100%;
  background-color: #201e48;

  flex-shrink: 0;
}

.ProfileTexts {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}
.ProfileTexts > span:nth-child(1) {
  color: #e8e7ec;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 122%; /* 18.3px */
}
.ProfileTexts > span:nth-child(2) {
  color: #e8e7ec;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 122%; /* 14.64px */
}

#ProfileProgressCard {
  position: relative;
  width: 100%;
  height: max-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;

  padding: 28px 32px;

  background: rgba(32, 30, 72, 0.7);

  /* shadow */
  box-shadow: 8px 0px 8px rgba(31, 18, 35, 0.15);
  border-radius: var(--o-s-global-border-radius);

  flex-shrink: 0;
}

.ProfileProgressCardBG {
  position: absolute;

  width: 100%;

  top: 0%;
  left: 0%;

  background-repeat: no-repeat;
  background-image: url("./../../assets/img/profile/ProfileContents/ProfileProgress.svg");
  background-position: calc(50% + 20px) 50%;
  background-size: 163px 167px;
}

.CardTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  /* students/white */
  color: #e8e7ec;
}

.ProfileHead > *,
.ProfileText > * {
  margin-top: auto;
  margin-bottom: auto;
}
.ProfileHead {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.SettingsButton {
  position: relative;
  display: block;

  cursor: pointer;

  width: 15px;
  height: 15px;

  background: url("./../../assets/img/profile/edit_icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.ProfileText {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;

  width: 100%;
  height: fit-content;
}
</style>

<style scoped>
@media (max-width: 480px) {
  .MyAchList {
    grid-template-columns: repeat(6, 30px);
    gap: 10px;
  }

  .ButtonAch {
    width: 30px;
    height: 30px;
  }

  .ArrowRight {
    font-size: 16px;
  }

  #ProfileProgressCard {
    padding: 21px 18px;
    height: fit-content;
    justify-content: space-between;
  }

  #ProfileProgressCard > .CardTitle:nth-child(1) {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 22px;
  }
  #ProfileProgressCard > .CardTitle:nth-child(2) {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 12px;
    line-height: 155%;
  }

  .ProfileProgressCardBG {
    background-position: 95% calc(50% - 5px);
    background-size: auto 65%;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  #ProfileProgressCard {
    justify-content: space-between;
    height: max-content !important;
  }

  #ProfileProgressCard > .CardTitle:nth-child(1) {
    margin-top: 10px;
    margin-bottom: auto;
  }
  #ProfileProgressCard > .CardTitle:nth-child(2) {
    margin-bottom: 10px;
  }

  .ProfileProgressCardBG {
    background-position: 95% calc(50% - 10px);
    background-size: auto 65%;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  #ProfileProgressCard {
    justify-content: space-between;
  }

  #ProfileProgressCard > .CardTitle:nth-child(1) {
    margin-top: 10px;
    margin-bottom: auto;
  }
  #ProfileProgressCard > .CardTitle:nth-child(2) {
    /* margin-bottom: 10px; */
  }

  .ProfileProgressCardBG {
    background-position: 95% calc(50% - 10px);
    background-size: auto 65%;
  }
}
@media (min-width: 992px) and (max-width: 1600px) {
}
</style>

<script>
import { getUserRegistrationFields } from "@/api/registration_info.js";
import { findByUserAchievements, seenAchievement } from "@/api/achievements.js";
import Octocoins from "@/components/CustomElements/Octocoins.vue";

import { getCoinsValueForUser } from "@/api/user_coins.js";

import { mapGetters } from "vuex";

import { getCoinsText } from "@/middlewares/getCoinsText.js";

export default {
  components: { Octocoins },
  name: "BetaProfileProgressCard",
  data() {
    return {
      openDropDown: false,
      UserGoal: "",

      UserAchievements: [],

      SeenAchievements: false,

      NewAchievementID: null,
      NewAchievementTitle: "",
      NewAchievementsList: [],

      coins_value: 0,
    };
  },
  watch: {
    user: {
      handler(val) {
        if (this.id) {
          this.id = this.user.id;
        }
      },
      deep: true,
    },
  },
  async created() {
    // let UserGoal = await getUserRegistrationFields(this?.user?.id);
    // if (UserGoal.data?.goals != null && UserGoal.data?.goals?.length != 0) {
    //   let Goals = [];
    //   for (let i of UserGoal.data?.goals) {
    //     if (i.type_value == "Другое") {
    //       Goals.push(i.other_value);
    //     } else {
    //       Goals.push(i.type_value);
    //     }
    //   }
    //   this.UserGoal = Goals.join(", ");
    // } else {
    //   this.UserGoal = "не указано";
    // }

    let _user_coins_resp = await getCoinsValueForUser(this?.user?.id);
    this.coins_value = _user_coins_resp.data;

    let _user_achievements_resp = await findByUserAchievements(this?.user?.id);
    this.UserAchievements = _user_achievements_resp.data;

    this.NewAchievementsList = this.UserAchievements.filter(
      (x) => x.seen === false
    );

    this.checkNewAchievement();
  },
  computed: {
    getCoinsText() {
      return getCoinsText(this.coins_value);
    },

    getFirstFiveAchievements() {
      return this.UserAchievements.slice(0, 5);
    },

    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
    getUserName() {
      return `${this?.user?.surname} ${this?.user?.name}`;
    },
    getJobFollowCounter() {
      return `${this?.user?.Jobs.length}`;
    },
    getFirstLetter() {
      return this?.user?.name.substring(0, 1);
    },

    getFormattedCoinsValue() {
      return new Intl.NumberFormat("ru-RU").format(this.coins_value);
    },
  },
  methods: {
    getAchIMG(ach_url) {
      return `
      background: url("${process.env.VUE_APP_BACK}/${ach_url
        .replaceAll("\\", "/")
        .replaceAll("public", "")}");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      `;
    },
    async checkNewAchievement() {
      if (this.NewAchievementID != null) {
        await seenAchievement(this?.user?.id, this.NewAchievementID);
      }

      if (
        this.NewAchievementsList != null &&
        this.NewAchievementsList.length != 0
      ) {
        let shifted = this.NewAchievementsList.shift();
        this.NewAchievementID = shifted.id;
        this.NewAchievementTitle = shifted.name;
        this.SeenAchievements = true;
      } else {
        this.SeenAchievements = false;
      }
    },
  },
};
</script>
