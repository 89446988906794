import request from "@/api/config.js";

export function getNewsByFilters(data) {
  return request({
    url: `news/get-news/by-filters`,
    method: "post",
    data,
  });
}
export function postDetails(link_name) {
  return request({
    url: `news/get-post-details/${link_name}`,
    method: "get",
  });
}

export function deletePostMaterial(mat_id) {
  return request({
    url: `news/post/delete-material/${mat_id}`,
    method: "get",
  });
}
export function uploadPostMaterials(product_id, data) {
  return request({
    url: `news/post/${product_id}/upload-materials`,
    method: "post",
    data,
  });
}
export function updatePost(product_id, data) {
  return request({
    url: `news/post/${product_id}/update`,
    method: "post",
    data,
  });
}
export function createPost(data) {
  return request({
    url: `news/post-create/new`,
    method: "post",
    data,
  });
}
