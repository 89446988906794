<template>
  <div id="ProfilePage">
    <Sidebar />

    <div id="PageStart" ref="PageStart" class="ProfileContent">
      <div class="ProfileBlocks">
        <div class="profileBlock BlockColumn1">
          <ProfileProgressCard />
          <NewsFeed />
        </div>
        <div class="profileBlock BlockColumn2">
          <slot name="ColumnTwo"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#ProfilePage {
  position: relative;
  width: 100%;
  height: 100vh;

  top: 0%;
  left: 0%;

  margin: 0%;
  padding: 0%;

  display: flex;
  flex-direction: row;

  overflow: hidden;

  background-image: radial-gradient(
    123.22% 129.67% at 100.89% -5.6%,
    #201d47 0%,
    #17153a 100%
  );
  background-position: 50% 50%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: auto 120%;
}

#ProfilePage > .ProfileContent {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 23px 32px 23px 21px;
}

#ProfilePage > .ProfileContent > .ProfileBlocks {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;

  margin: 0%;
  padding: 0%;
}

#ProfilePage > .ProfileContent > .ProfileBlocks > .profileBlock {
  position: relative;
  height: 100%;
}

#ProfilePage > .ProfileContent > .PageHeader {
  position: relative;
  width: 100%;

  margin-bottom: 32px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;

  /* identical to box height */

  /* students/white */
  color: #e8e7ec;
}

.BlockColumn1,
.BlockColumn2 {
  margin-right: 32px;
  flex-shrink: 0;
}
.BlockColumn1 {
  display: flex;
  flex-direction: column;
  width: 398px;
}
.BlockColumn2 {
  display: flex;
  flex-direction: column;
  width: 1280px;
  overflow-y: auto !important;
}

.BlockColumn1 > *:not(:nth-last-child(1)) {
  margin-bottom: 32px;
}
.BlockColumn1 > *:not(:nth-last-child(1)) {
  margin-bottom: 40px;
}
</style>

<style scoped>
@media (max-width: 480px) {
  #ProfilePage .BlockColumn1 {
    height: fit-content !important;
  }

  #ProfilePage .BlockColumn2 {
    margin-bottom: 70px !important;
  }

  #ProfilePage .ProfileBlocks .BlockColumn1 > *:nth-child(1) {
    height: fit-content;
    width: 100% !important;
    margin-bottom: 00px !important;
  }
  #ProfilePage .ProfileBlocks .BlockColumn2 > *:nth-child(2) {
    margin-bottom: 80px;
  }
}

@media (max-width: 992px) {
  #ProfilePage .ProfileBlocks .BlockColumn2 > *:nth-child(2) {
    margin-bottom: 80px;
  }

  #ProfilePage {
    overflow: auto;
  }

  #ProfilePage > .ProfileContent > .ProfileBlocks {
    flex-wrap: wrap;
    padding: 0% 32px 0% 21px;

    row-gap: 20px;
    column-gap: 40px;
  }

  #ProfilePage > .ProfileContent > .ProfileBlocks .profileBlock {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    padding: 0%;
  }

  #ProfilePage > .ProfileContent {
    overflow: auto;
    padding: 23px 0px 23px 0px;
  }

  .BlockColumn1,
  .BlockColumn2 {
    margin-right: 0px;
    flex-shrink: 1;
  }
  .BlockColumn1 {
    flex-direction: column;
    width: 100%;
    max-width: 100%;

    height: 150px !important;

    row-gap: 40px;
    column-gap: 40px;

    order: 1;
  }
  .BlockColumn2 {
    flex-direction: column;
    width: 100%;
    max-width: 100%;

    height: fit-content !important;

    order: 3;
  }
  .BlockColumn3 {
    width: 100%;
    max-width: 100%;

    height: auto !important;
    max-height: 400px;

    padding-bottom: 21px;
    order: 2;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(1) {
    height: 150px;
    width: 100% !important;
    margin-bottom: 40px !important;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(2) {
    display: none;
  }

  #ProfilePage .BlockColumn2 > *:nth-child(2) {
    flex-shrink: 0;
    padding-bottom: 23px;
    height: 600px;

    flex: auto;
    overflow: auto;
  }

  .BlockColumn1 > *:not(:nth-last-child(1)) {
    margin-bottom: 15px;
  }
  .BlockColumn1 > *:not(:nth-last-child(1)) {
    margin-bottom: 15px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  #ProfilePage {
    overflow: auto;
  }

  #ProfilePage > .ProfileContent > .ProfileBlocks {
    flex-wrap: wrap;
    padding: 0% 32px 0% 21px;

    row-gap: 40px;
    column-gap: 40px;
  }

  #ProfilePage > .ProfileContent {
    overflow: auto;
    padding: 23px 0px 23px 0px;
  }

  .BlockColumn1,
  .BlockColumn2 {
    margin-right: 0px;
    flex-shrink: 1;
  }
  .BlockColumn1 {
    flex-direction: row;
    width: 100%;
    max-width: 100%;

    height: 350px !important;

    row-gap: 40px;
    column-gap: 40px;

    order: 1;
  }
  .BlockColumn2 {
    width: calc(94% + 40px);
    max-width: calc(94% + 40px);

    height: fit-content !important;

    order: 2;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(1) {
    height: 350px;
    width: 47% !important;
    margin-bottom: 40px !important;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(2) {
    width: 47% !important;
    box-shadow: none !important;
  }

  #ProfilePage .BlockColumn2 > *:nth-child(2) {
    flex-shrink: 0;
    padding-bottom: 23px;
    height: 600px;

    flex: auto;
    overflow: auto;
  }

  .BlockColumn1 > *:not(:nth-last-child(1)) {
    margin-bottom: 32px;
  }
  .BlockColumn1 > *:not(:nth-last-child(1)) {
    margin-bottom: 40px;
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  #ProfilePage {
    overflow: auto;
  }

  #ProfilePage > .ProfileContent > .ProfileBlocks {
    flex-wrap: wrap;
    padding: 0% 32px 0% 21px;

    row-gap: 40px;
    column-gap: 40px;
  }

  #ProfilePage > .ProfileContent {
    overflow: auto;
    padding: 23px 0px 23px 0px;
  }

  .BlockColumn1,
  .BlockColumn2 {
    margin-right: 0px;
    flex-shrink: 1;
  }
  .BlockColumn1 {
    flex-direction: row;
    width: 100%;
    max-width: 100%;

    height: 350px !important;

    row-gap: 40px;
    column-gap: 40px;

    order: 1;
  }
  .BlockColumn2 {
    width: calc(94% + 40px);
    max-width: calc(94% + 40px);

    height: fit-content !important;

    order: 2;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(1) {
    height: 350px;
    width: 57% !important;
    margin-bottom: 40px !important;
  }

  #ProfilePage .BlockColumn1 > *:nth-child(2) {
    width: 37% !important;
    box-shadow: none !important;
  }

  #ProfilePage .BlockColumn2 > *:nth-child(2) {
    flex-shrink: 0;
    padding-bottom: 23px;
    height: 600px;

    flex: auto;
    overflow: auto;
  }

  .BlockColumn1 > *:not(:nth-last-child(1)) {
    margin-bottom: 32px;
  }
  .BlockColumn1 > *:not(:nth-last-child(1)) {
    margin-bottom: 40px;
  }
}
@media (min-width: 1600px) {
  .BlockColumn1,
  .BlockColumn2 {
    margin-right: 32px;
    flex-shrink: 1;
  }
  .BlockColumn1 {
    width: 25%;
    max-width: 398px;
  }
  .BlockColumn2 {
    width: 75%;
    max-width: 1280px;
  }

  .BlockColumn1 > *:not(:nth-last-child(1)) {
    margin-bottom: 32px;
  }
  .BlockColumn1 > *:not(:nth-last-child(1)) {
    margin-bottom: 40px;
  }

  .BlockColumn2 > *:not(:nth-last-child(2)) {
    flex: auto;
    overflow-y: auto;
  }

  #ProfilePage > .ProfileContent > .ProfileBlocks {
    justify-content: center;
  }
}
</style>

<script>
import { findById } from "@/api/user.js";
import { mapGetters, mapMutations } from "vuex";
import { refresh } from "@/api/auth.js";

import NewsFeed from "@/components/Profile/NewsFeed.vue";
import Sidebar from "@/components/Profile/Sidebar.vue";
import ProfileProgressCard from "@/components/Profile/BetaProfileProgressCard.vue";

export default {
  data() {
    return {};
  },
  name: "TwoColumnsTemplate",
  components: {
    Sidebar,
    ProfileProgressCard,
    NewsFeed,
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
    userLevels() {
      return this.user.user_cells.length > 0
        ? this.user.user_cells.map((cell) => cell.level_id)
        : [];
    },
    enoughCompetenceForJob() {
      const projectLevels = this.user?.UserPinTask[0].levels.map(
        (level) => level.id
      );
      return projectLevels.length > 0
        ? projectLevels.every(
            (element, index) => element === this.userLevels[index]
          )
        : false;
    },

    tipContent() {
      // return "<b>Компетенции получены!</b> К сожалению, твой коллега опередил тебя и приступил к задаче раньше. Но не расстраивайся, я подобрал для тебя подборку задач, которые подойдут тебе с твоими компетенциями :) <br/><br/> Изучи мою подборку, выбери задачу которая тебе понравилась, закрепи ее и следуй дальнейшим указаниям!";
      // return "<b>Компетенции получены!</b> После того, как все необходимые навыки были изучены, тебе необходимо нажать кнопку приступить к задаче и следовать дальнейшим указаниям. <br /> <br /> P.S. Кнопка <b>приступить к задаче</b> уже появилась в закрепленной задаче, скорее нажимай!";

      return "test";
    },
  },
  watch: {
    user: {
      handler(newValue, oldValue) {
        if (this.id) {
          this.id = this.user.id;
        }
      },
      deep: true,
    },
  },

  created() {
    if (this.user == null) {
      this.updateUser();
    } else {
      this.getUser(this.user.id);
    }
  },
  mounted() {},
  methods: {
    ...mapMutations("auth", ["SET_USER"]),
    async updateUser() {
      const response = await refresh();
      const userResponse = response.user;
      this.getUser(userResponse.id);
    },
    getUser(id) {
      if (id != undefined) {
        findById(id).then((response) => {
          this.SET_USER(response.data);
        });
      }
    },
  },
};
</script>
