<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
  >
    <path
      d="M11 21.125C5.68426 21.125 1.375 16.8157 1.375 11.5C1.375 6.18426 5.68426 1.875 11 1.875C16.3157 1.875 20.625 6.18426 20.625 11.5C20.625 16.8157 16.3157 21.125 11 21.125ZM11 22.5C17.0751 22.5 22 17.5751 22 11.5C22 5.42487 17.0751 0.5 11 0.5C4.92487 0.5 0 5.42487 0 11.5C0 17.5751 4.92487 22.5 11 22.5Z"
      fill="#B84BB6"
    />
    <path
      d="M7.22578 8.45595C7.21534 8.64325 7.36987 8.79498 7.55746 8.79498H8.69113C8.88098 8.79498 9.03193 8.64018 9.05744 8.45205C9.17975 7.54987 9.79894 6.89227 10.9027 6.89227C11.8453 6.89227 12.7094 7.36359 12.7094 8.49823C12.7094 9.37103 12.1944 9.77252 11.3827 10.3835C10.4575 11.0555 9.72439 11.8411 9.77676 13.1154L9.78084 13.4135C9.78342 13.6015 9.93655 13.7525 10.1246 13.7525H11.2397C11.4296 13.7525 11.5835 13.5986 11.5835 13.4088V13.2637C11.5835 12.2775 11.9588 11.9894 12.9712 11.2214C13.8091 10.5842 14.6819 9.87726 14.6819 8.39349C14.6819 6.31622 12.9276 5.3125 11.0074 5.3125C9.26518 5.3125 7.35575 6.12459 7.22578 8.45595ZM9.36654 16.3796C9.36654 17.1128 9.95132 17.6539 10.7543 17.6539C11.5922 17.6539 12.1682 17.1128 12.1682 16.3796C12.1682 15.6203 11.5922 15.0879 10.7543 15.0879C9.95132 15.0879 9.36654 15.6203 9.36654 16.3796Z"
      fill="#B84BB6"
    />
  </svg>
</template>

<script>
export default {
  name: "CircleQuestionIcon",
};
</script>

<style scoped>
svg{
    cursor: pointer;
}
</style>
