<template>
  <div class="tip-block">
    <div class="logo-block">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
      >
        <circle cx="24" cy="24" r="24" fill="#FAFAFA" />
        <mask
          id="mask0_5146_21038"
          style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="7"
          y="8"
          width="34"
          height="32"
        >
          <path
            d="M29.4368 10.9317L24.0054 8.61719L18.574 10.9317L13.0967 13.1357L10.8928 18.6129L9.21756 22.544H13.4603L14.5301 20.0336L16.098 16.137L19.9946 14.569L23.8586 12.9224L27.7226 14.569L31.6192 16.137L33.1871 20.0336L34.2569 22.544L40.3219 22.544L37.7385 20.0691L37.118 18.6129L34.914 13.1357L29.4368 10.9317Z"
            fill="#D9D9D9"
          />
          <path
            d="M37.118 29.4758L38.9044 25.2837H34.243L33.1871 27.7616L31.6192 31.6582L27.7226 33.2261L23.8586 34.8727L19.9946 33.2261L16.098 31.6582L14.5301 27.7616L13.4742 25.2837H7.59961L10.1612 27.7589L10.8928 29.4758L13.0967 34.953L18.574 37.157L24.0054 39.4715L29.4368 37.157L34.914 34.953L37.118 29.4758Z"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_5146_21038)">
          <rect
            x="4.92578"
            y="24.043"
            width="36.6599"
            height="15.4272"
            fill="url(#paint0_linear_5146_21038)"
          />
          <rect
            x="4.92578"
            y="6.95312"
            width="36.6925"
            height="17.0905"
            fill="#1E768E"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_5146_21038"
            x1="21.597"
            y1="15.6798"
            x2="11.7663"
            y2="42.3071"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#C548AB" />
            <stop offset="1" stop-color="#635BFF" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div v-html="content" lang="ru" class="text-block"></div>
  </div>
</template>

<script>
export default {
  name: "TipBlock",
  props: {
    content: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
};
</script>

<style scoped>
.tip-block {
  position: relative;
  display: flex;

  padding: 1.25rem 1.63rem;

  flex-direction: row;
  justify-content: flex-start;

  width: 100%;
  height: fit-content;

  gap: 0.9375rem;
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
  flex-basis: auto !important;

  border-radius: 1.25rem;
  background: linear-gradient(91deg, #1e768e 2.08%, #9652d4 98.48%);

  /* shadow */
  box-shadow: 8px 0px 8px 0px rgba(31, 18, 35, 0.15);

  margin-bottom: 1.88rem;
}

.text-block {
  position: relative;
  display: block;

  margin: auto 0%;

  width: 100%;
  height: fit-content;

  color: #fff;
  font-family: Montserrat;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;

  hyphens: auto;
}
.logo-block {
  margin: auto 0%;
}
</style>

<style scoped>
@media (max-width: 1024px) {
  .logo-block {
    display: none;
  }
  .text-block {
    font-size: 0.7375rem;
  }
}
</style>
